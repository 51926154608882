import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDeckSaS2LmzwKndTguqDjeDdHJPyXpRW8",
    authDomain: "jt-kim-project.firebaseapp.com",
    projectId: "jt-kim-project",
    storageBucket: "jt-kim-project.appspot.com",
    messagingSenderId: "471562711298",
    appId: "1:471562711298:web:ca9932a8cca07e8f6e121b",
    measurementId: "G-SD4NM1P9RP"
};

export const firebaseApp = initializeApp(firebaseConfig);