import logo from './logo.svg';
import './App.css';
import React from "react";
import {firebaseApp} from "./firebase";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const messaging = getMessaging(firebaseApp);
onMessage(messaging, (payload) => {
    alert(payload.notification.title + "\n" + payload.notification.body);
});

getToken(messaging, {vapidKey: "BMOTRLvkgCsAboo5UPffBluBbgcWd2_WAbJ3G9O-_8SvLecpgY75I0pkOK5ta1mEBIGKS7_5vJIHXivh1o1c0Fs"}).then((currentToken) => {
    if (currentToken) {
        console.log("currentToken");
        console.log(currentToken);
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
});

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

export default App;
